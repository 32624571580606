<div class="row" *ngIf="style2022$ | async">
  <div class="col-md-4 mr-auto breadcrumb-container">
    <app-breadcrumbs [breadcrumbKeyValues]="breadcrumbKeyValues"></app-breadcrumbs>
  </div>
</div>

<div class="row" *ngIf="!(style2022$ | async)">
  <div class="col-md-10 old-breadcrumb">
    <a class="back-profile" [routerLink]="['/profile']"><i class="fa fa-chevron-left"></i> {{ trans.trans.backToProfile.value }}</a>
  </div>
</div>

<p-menu #row appendTo="body" [popup]="true" [model]="rowLimit" [styleClass]="style2022$ ? 'style-2022' : null"></p-menu>

<div class="models_panel" style="margin-bottom: 20px">
  <div class="models_page_header_container_small">
    <div class="keywest_header">
      <div class="row">
        <div class="col-xs-10">
          <div class="title">
            {{ trans.trans.manage_user_group.value }}
            <app-editTranslation
              *ngIf="showTranslate"
              (callback)="getTranslations()"
              key="trans.manage_user_group"
              [isTranslationV2]="true"
              [component]="trans.config.component"
              [transObj]="trans.trans.manage_user_group"
            ></app-editTranslation>
          </div>
        </div>
        <div class="col-xs-2">
          <a (click)="addGroup()" class="btn btn_customizable pull-right"><i class="fa fa-plus"></i>{{ trans.trans.add.value }}</a>
        </div>
      </div>
    </div>
    <div class="row" style="margin-bottom: 20px">
      <div class="col-xs-4">
        <div class="input-group keywest_search">
          <span class="input-group-addon"><i class="fa fa-search"></i></span>
          <input
            [(ngModel)]="strSearch"
            (keyup)="adminGroups.filter($any($event).target.value, 'name', 'contains')"
            type="text"
            placeholder="{{ trans.trans.search.value }}"
            class="form-control"
          />
          <span *ngIf="strSearch !== ''" (click)="strSearch = ''; adminGroups.filter('', 'name', 'contains')" class="input-group-addon clickable"><i class="fa fa-times"></i></span>
        </div>
      </div>
      <div class="col-xs-8">
        <span class="clickable pull-right" (click)="row.toggle($event)">{{ trans.trans.rowLimit?.value }} {{ pageLimit }} <i class="fa fa-chevron-down"></i></span>
      </div>
    </div>
    <div class="row">
      <div class="col-xs-12">
        <p-table
          #adminGroups
          dataKey="userGroups"
          [paginator]="true"
          [rows]="10"
          [value]="userGroups"
          [resizableColumns]="true"
          styleClass="keywest_table"
          tableStyleClass="keywest_table"
        >
          <ng-template pTemplate="header" let-columns>
            <tr>
              <th style="width: 20%">
                {{ trans.trans.group.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.group"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.group"
                ></app-editTranslation>
              </th>
              <th class="new-style-only">
                {{ trans.trans.description.value
                }}<app-editTranslation
                  *ngIf="showTranslate"
                  (callback)="getTranslations()"
                  key="trans.description"
                  [isTranslationV2]="true"
                  [component]="trans.config.component"
                  [transObj]="trans.trans.description"
                ></app-editTranslation>
              </th>
              <th></th>
            </tr>
          </ng-template>
          <ng-template pTemplate="body" let-editing="editing" let-group let-columns="columns">
            <tr>
              <td>
                <span class="clickable" [class.link]="style2022$" (click)="style2022$ && toggleEdit(group)">
                  {{ group.name }}
                </span>
                <span *ngIf="group?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="group.description"> info </span>
              </td>
              <td class="new-style-only">{{ group.description | textTruncate: 50 }}</td>
              <td>
                <span class="keywest_icon_group pull-right">
                  <span><i (click)="toggleEdit(group)" [matTooltip]="trans.trans.edit.value" class="clickable fa fa-pencil"></i></span>
                  <span>
                    <app-are-you-sure
                      [matTooltip]="trans.trans.delete.value"
                      [message]="trans.trans.areYouSure.value"
                      text=""
                      icon="fieldset_icon fa fa-trash"
                      (callbackYes)="deleteGroup(group)"
                    ></app-are-you-sure>
                  </span>
                </span>
              </td>
            </tr>
          </ng-template>
        </p-table>
      </div>
    </div>
  </div>
</div>
<p-sidebar [(visible)]="showSidebar" styleClass="keywest_sidebar p-sidebar-lg" position="right" (onHide)="cancelEdit()">
  <div class="sidebar_wrapper">
    <div class="sidebar_header">
      {{ sidebarTitle?.value }}
    </div>
    <div class="sidebar_container">
      <div class="keywest_panel">
        <form [formGroup]="userGroupForm">
          <div class="row">
            <div class="col-md-12">
              <app-error-input-wrapper [errorMessage]="errorDict.get('name')" [showOnTouched]="true">
                <label for="name" class="sidebar_label"
                  >{{ trans.trans.name.value }}
                  <app-editTranslation
                    *ngIf="showTranslate"
                    [component]="trans.config.component"
                    [isTranslationV2]="true"
                    [transObj]="trans.trans.name"
                    (callback)="getTranslations()"
                  ></app-editTranslation>
                </label>
                <input type="text" class="form-control" formControlName="name" name="name" [placeholder]="trans.trans.name.value" />
              </app-error-input-wrapper>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <label for="description" class="sidebar_label"
                >{{ trans.trans.description.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.description"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </label>
              <textarea name="description" pInputTextarea formControlName="description" rows="4" class="form-control"></textarea>
            </div>
          </div>
        </form>
        <div class="row users" *ngIf="!usersLoading; else loading">
          <div class="available">
            <div>
              <h5 class="list-header">{{ trans.trans.availableUsers.value }}</h5>
              <p-listbox
                [options]="availableUsers"
                [metaKeySelection]="false"
                [checkbox]="true"
                [filter]="true"
                [multiple]="true"
                optionLabel="name"
                [listStyle]="{ 'max-height': '290px', 'min-height': '290px' }"
                [style]="{ width: '100%', border: 'none' }"
                class="group-select"
                styleClass="DLStyle"
                [(ngModel)]="selectedUsers"
              >
                <ng-template let-user pTemplate="item">
                  <div class="list-item">
                    <span>{{ user.name }}</span>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>
          <div class="controls-container">
            <div class="controls">
              <button class="btn-action" (click)="addUsers()">
                <i class="fa fa-angle-right"></i>
              </button>
              <button class="btn-action" (click)="addAllUsers()">
                <i class="fa fa-angle-double-right"></i>
              </button>
              <button class="btn-action" (click)="removeUsers()">
                <i class="fa fa-angle-left"></i>
              </button>
              <button class="btn-action" (click)="removeAllUsers()">
                <i class="fa fa-angle-double-left"></i>
              </button>
            </div>
          </div>
          <div class="members">
            <div>
              <h5 class="list-header">{{ trans.trans.selectedUsers.value }}</h5>
              <p-listbox
                [options]="existingGroupMembers"
                [metaKeySelection]="false"
                [checkbox]="true"
                [filter]="true"
                [multiple]="true"
                optionLabel="name"
                [listStyle]="{ 'max-height': '290px', 'min-height': '290px' }"
                [style]="{ width: '100%', border: 'none' }"
                styleClass="DLStyle"
                [(ngModel)]="selectedMembers"
              >
                <ng-template let-user pTemplate="item">
                  <div class="list-item">
                    <span>{{ user.name }}</span>
                  </div>
                </ng-template>
              </p-listbox>
            </div>
          </div>
        </div>
        <ng-template #loading>
          <div class="row">
            <div class="col-md-12 marg-v">
              <p-progressBar mode="indeterminate"></p-progressBar>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="btn_container">
      <div class="row">
        <div class="col-md-12">
          <button *ngIf="mode === 'edit'" [disabled]="userGroupForm?.invalid || isLoading" (click)="saveEdit()" class="btn btn_customizable pull-right">
            {{ trans.trans.update.value }}
          </button>
          <button *ngIf="mode === 'add'" [disabled]="userGroupForm?.invalid || isLoading" (click)="save()" class="btn btn_customizable pull-right">
            {{ trans.trans.save.value }}
          </button>
          <app-are-you-sure
            matTooltipPosition="above"
            [message]="trans.trans.areYouSure.value"
            class="btn cancel-confirm pull-right marg-h"
            icon=""
            [text]="trans.trans.back.value"
            (callbackYes)="cancelEdit()"
          ></app-are-you-sure>
        </div>
      </div>
    </div>
  </div>
</p-sidebar>
<p-menu #rowMenu appendTo="body" [popup]="true" [model]="menu" [styleClass]="style2022$ ? 'style-2022' : null"></p-menu>
