<div class="sidebar_wrapper">
    <div class="sidebar_header">
        <div class="title flyout-title">
            {{ trans.trans.addThemes.value }}
            <app-editTranslation *ngIf="showTranslate" [component]="trans.config.component" [isTranslationV2]="true"
                [transObj]="trans.trans.addThemes" (callback)="getTranslations()"></app-editTranslation>
        </div>
    </div>
    <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
    <div class="content-wrapper">
        <p>{{ trans.trans.uploadThemeFile.value }}</p>
            <p-accordion>
                <p-accordionTab *ngFor="let theme of themes" [header]="theme.name">
                    <iframe *ngIf="theme.download_url" [src]="theme.download_url" width="400px" height="300px"></iframe>
                </p-accordionTab>
                </p-accordion>

        <div class="file-upload-container">
            <div *ngIf="themes?.length === 2">
                <p-checkbox 
                class="override-checkbox"
                *ngIf="themes?.length === 2"
                [(ngModel)]="overrideExistingtemplates" 
                [binary]="true" 
                inputId="override" >
                </p-checkbox>
                <label class="ovverride-label" for="override">{{trans.trans.OverrideExistingStandardTemplates.value}}</label>
            </div>
            <span (click)="triggerFileInput()" class="btn btn_customizable">
                {{trans.trans.chooseFile.value}}
            </span>
            <input #fileUpload id="fileUpload" type="file" (change)="onFileChange($event)" style="display: none;"/>
            <span class="upload-file-label" *ngIf="selectedFile.file">{{selectedFile.name}}</span>
            <span (click)="uploadFile()" [matTooltip]="trans.trans.uploadFile.value" *ngIf="selectedFile.file" class="upload-button material-icons-outlined tooltip-custom fa fa-upload"></span>
            <button *ngIf="themes?.length === 2" (click)="onGenerateStandardAssetsClick()" class="btn btn_customizable generate-asset-btn">
                <span>{{trans.trans.generateStandardAssets.value}}</span>
            </button>
        </div>
    </div>
</div>