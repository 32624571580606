import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AssetService } from '@data/services/asset/asset.service';
import { TranslationsV2Service } from '@data/services/translationsv2/translationsv2.service';
import { NotificationService } from '@services/notification.service';
import { forkJoin, of, Subject } from 'rxjs';
import { finalize, take, takeUntil, map, tap } from 'rxjs/operators';
import { AssetsManageProfileTranslations } from '../assets.translation';

import { CommonService } from '@data/services/common/common.service';
import { Template } from '@data/services/valueprop/models/asset.model';
import { Solution } from '@data/services/valueprop/models/solution.model';
import { ReturnApiError } from '@shared/models/error.model';
import { AssetElementPayload, AssetTemplate } from '@shared/models/asset.models';
import { SelectedAsset } from '../interfaces/selected-asset.interface';
import { AssetSettingsComponent } from '../asset-settings/asset-settings.component';
import { DomSanitizer } from '@angular/platform-browser';

interface Step {
  label: string;
}
@Component({
  selector: 'app-ae-assets',
  templateUrl: './ae_asset.component.html',
  styleUrls: ['./ae_asset.component.scss'],
})
export class AEAssetsComponent implements OnInit, OnDestroy {
  @Input() mode: 'add' | 'edit' = 'add';
  @Input() selectedElem: Partial<AssetTemplate>;
  @Input() solutions: Solution[] = [];
  @Output() callback = new EventEmitter();
  @Output() warningNoSave = new EventEmitter<boolean>();
  @ViewChild(AssetSettingsComponent) assetSettingsRef: AssetSettingsComponent;

  ngUnsubscribe = new Subject();
  templates: Template[] = [];

  accountAssetId: string;
  selectedAsset: SelectedAsset = {
    asset_type_id: '',
    customer_facing: null,
    section_type_id: '',
    format_type_id: ''
  };

  actionLoader = false;
  showWarning = false;

  steps: Step[] = [{ label: 'Step 1' }, { label: 'Step 2' }, { label: 'Step 3' }];
  activeIndex = 0;
  enableSaveInElements = false;

  loadingLoadEditDetails = false;
  loadingSave = false;
  showTranslate = false;

  constructor(
    private assetService: AssetService,
    private notificationService: NotificationService,
    private translationService: TranslationsV2Service,
    public trans: AssetsManageProfileTranslations,
    private commonService: CommonService,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    if (this.mode === 'edit') {
      this.accountAssetId = this.selectedElem.account_asset_id;
    } else {
      this.accountAssetId = '';
    }

    this.activeIndex = 0;
    this.emitFlagShowWarning(false);
    this.getTranslations();
    if (this.mode === 'edit') {
      this.loadEditDetails(this.selectedElem);
    }
    this.commonService.notifyEditTranslation$.pipe(takeUntil(this.ngUnsubscribe)).subscribe((res) => {
      this.showTranslate = res;
    });

    this.assetService.refreshTemplate$.pipe(takeUntil(this.ngUnsubscribe)).subscribe(() => {
      this.loadEditDetails(this.selectedElem, true);
    });

    this.conditionalIFrameReloadTimeout();
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next(false);
    this.ngUnsubscribe.complete();
  }

  getTranslations(): void {
    const langId = sessionStorage.getItem('language_type_id');
    const langAbbr = this.translationService.getLanguageAbbr(langId);

    const payload = {
      account_id: sessionStorage.getItem('aid'),
      component: this.trans.config.component,
      lang: langAbbr,
      localTranslations: this.trans.trans,
    };

    this.translationService
      .getComponentTrans(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((res) => {
        this.trans.trans = this.commonService.mergeObject(this.trans.trans, res);
      });
  }

  conditionalIFrameReloadTimeout() {
    setTimeout(() => {
      let iFrames = (document.getElementsByTagName("iFrame") as HTMLCollectionOf<HTMLIFrameElement>);

      for ( let i = 0; i < iFrames?.length; i++) {
        try {
          if (iFrames[i].contentWindow.document?.URL === 'about:blank') {    
            iFrames[i].src = iFrames[i].src;
            
            this.conditionalIFrameReloadTimeout()
          }
        } catch (e) {
          // Do nothing, just prevent the console error 
        }
      }
    }, 1000)
  }

  manuallyAssignTemplate( payload ) {
    this.assetService
      .manuallyAssignTemplatePath(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.loadEditDetails(this.selectedElem, true);
      });
  }

  loadEditDetails(asset: Partial<AssetTemplate>, loadingInPlace: boolean = false): void {
    this.templates = [
      {
        language_type_id: '',
        name: '',
        description: '',
        artifact_name: '',
        default_active: '0',
      },
    ];

    if ( this.accountAssetId === '' ) {
      // Only reset if we don't have this
      this.accountAssetId = asset.account_asset_id ? asset.account_asset_id : this.selectedElem.account_asset_id;
    }

    this.loadingLoadEditDetails = true;

    this.assetService
      .getAsset(this.accountAssetId)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        if (response && response.result) {
          this.selectedAsset.asset_type_id = response.result.asset_type_id;
          this.selectedAsset.customer_facing = response.result.customer_facing === '1' ? true : false;
          this.selectedAsset.section_type_id = response.result.section_type_id;
          this.selectedAsset.format_type_id = response.result.format_type_id;

          if (response.result.templates) {
            let refreshIframe = false;
            this.templates = response.result.templates.filter((template) => !template.deleted_at);

            for (let i = 0; i < this.templates.length; i++) {
              if (this.templates[i].default_active === '1') {
                this.templates[i].solutionsSelected = [...this.solutions];
              } else if (this.templates[i].default_active === null && this.templates[i].solutions.length > 0) {
                const solutionSelected = this.templates[i].solutions.map((solution) => solution.account_solution_id);

                this.templates[i].solutionsSelected = [...this.solutions.filter((solution) => solutionSelected.includes(solution.id))];
              } else if (this.templates[i].default_active === '0') {
                this.templates[i].solutionsSelected = [];
              }

              if (this.templates[i].pdf === '1') {
                this.templates[i].pdf = true;
              } else {
                this.templates[i].pdf = false;
              }

              if ( this.templates[i].files?.length > 0 ) {
                this.templates[i].iframe_url = this.generateIFrameUrl(this.templates[i].files[0].link);
                refreshIframe = true;
              }
            }

            if ( refreshIframe && loadingInPlace ) {
              // Only try to refresh if we're loading in place and we have iframes to reload
              this.conditionalIFrameReloadTimeout();
            }
          }
        }
        this.loadingLoadEditDetails = false;
      });
  }

  generateIFrameUrl(link: string) {
    let gDriveUrl = 'https://docs.google.com/gview';
    const iframe_url = `${gDriveUrl}?url=${link}&embedded=true`

    return this.sanitizer.bypassSecurityTrustResourceUrl(iframe_url);
  }

  // Step 1
  updateAccountAssets(): void {
    const payload = {
      id: this.accountAssetId,
      customer_facing: this.selectedAsset.customer_facing ? '1' : '0',
    };
    this.assetService
      .editAccountAssets(this.accountAssetId, payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.activeIndex = 1;
        this.conditionalIFrameReloadTimeout();
        this.emitFlagShowWarning(false);
      });
  }

  // Step 1
  saveAccountAssets(): void {
    const accountID = sessionStorage.getItem('aid');
    const payload = {
      account_id: accountID,
      asset_type_id: this.selectedAsset.asset_type_id,
      customer_facing: this.selectedAsset.customer_facing ? '1' : '0',
    };
    this.assetService
      .saveAccountAssets(payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((response) => {
        this.accountAssetId = response.result.toString();
        this.activeIndex = 1;
        this.conditionalIFrameReloadTimeout();
        this.emitFlagShowWarning(false);
        this.loadEditDetails(this.selectedAsset);
      });
  }

  // step 2
  saveAssetTemplate(incrementStep: boolean = true): void {
    this.loadingSave = true;

    const saveTemplateRequests = this.templates.map((assetTemplate, i) => {
      const payload = new FormData();
      if (assetTemplate.tmpfile) {
        payload.append('assetTemplateFile', assetTemplate.file, assetTemplate.file.name);
      } else if (assetTemplate.template_path) {
        payload.append('template_path', assetTemplate.template_path);
      } else {
        this.notificationService.error('Asset Template ' + (i + 1) + ' requires a file', false);
        this.loadingSave = false;
        return of({ error: true });
      }
      const pdf = assetTemplate.pdf ? '1' : '0';
      payload.append('account_asset_id', this.accountAssetId);
      if (!assetTemplate.language_type_id) {
        this.notificationService.error('Asset Template ' + (i + 1) + ' is missing the Language', false);
        this.loadingSave = false;
        return of({ error: true });
      }
      payload.append('language_type_id', assetTemplate.language_type_id);
      if (assetTemplate.name.replace(/\s/g, '') === '') {
        this.notificationService.error('Asset Template ' + (i + 1) + ' is missing the Template name', false);
        this.loadingSave = false;
        return of({ error: true });
      }
      payload.append('name', assetTemplate.name);
      payload.append('description', assetTemplate.description);
      payload.append('pdf', pdf);
      if (assetTemplate.artifact_name.replace(/\s/g, '') === '') {
        this.notificationService.error('Asset Template ' + (i + 1) + ' is missing the Asset name', false);
        this.loadingSave = false;
        return of({ error: true });
      }
      payload.append('artifact_name', assetTemplate.artifact_name);
      payload.append('default_active', assetTemplate.default_active);

      if (assetTemplate.default_active === null && assetTemplate.solutionsSelected.length > 0) {
        assetTemplate.solutionsSelected.forEach((solution) => {
          payload.append('solutions[]', solution.id);
        });
      }

      if (assetTemplate.id) {
        return this.assetService.editTemplate(assetTemplate.id, payload).pipe(
          map((response) => {
            if (response.result.success) {
              this.templates[i].id = response.result.template.id;

              return response;
            } else {
              this.notificationService.error(response.result.message, false);
              return { error: true };
            }
          })
        );
      } else {
        return this.assetService.uploadTemplate(payload).pipe(
          map((response) => {
            if (response.result.success) {
              this.templates[i].id = response.result.template_id.toString();

              return response;
            } else {
              this.notificationService.error(response.result.message, false);
              return { error: true };
            }
          })
        );
      }
    });

    forkJoin(saveTemplateRequests)
      .pipe(
        take(1),
        finalize(() => (this.loadingSave = false))
      )
      .subscribe((response: any[] | ReturnApiError[]) => {
        const hasError = response.find((res) => res && res.error);
        if (!hasError) {
          this.loadEditDetails(this.selectedElem);

          // Refresh elements
          this.assetSettingsRef.getAssetElementTypes();

          if ( incrementStep ) {
            this.activeIndex++;
          } else {
            this.conditionalIFrameReloadTimeout()
          }

          this.emitFlagShowWarning(false);
        }
      });
  }

  populateSaveSettings(): void {
    this.assetSettingsRef.populateSaveSettings();
  }

  // step 3
  saveAssetElements(payload: AssetElementPayload): void {
    this.loadingSave = true;
    this.emitFlagShowWarning(false);

    if (this.mode === 'edit') {
      this.assetService
        .editAssetElements(this.accountAssetId, payload)
        .pipe(finalize(() => (this.loadingSave = false)))
        .subscribe(() => {
          this.callback.emit();
        });
    } else {
      this.assetService
        .saveAssetElements(this.accountAssetId, payload)
        .pipe(finalize(() => (this.loadingSave = false)))
        .subscribe(() => {
          this.callback.emit();
        });
    }
  }

  emitFlagShowWarning(flag = true): void {
    this.showWarning = flag;
    this.warningNoSave.emit(flag);
  }

  notifyChanges() {
    this.emitFlagShowWarning();
  }

  goBack(): void {
    if (this.activeIndex === 1 && this.showWarning) {
      this.notificationService.error(this.trans.trans.noSaveWarning.value, false);
    }
    this.activeIndex--;

    if ( this.activeIndex == 1 ) {
      this.conditionalIFrameReloadTimeout();
    }
  }

  deleteTemplate(template: AssetTemplate): void {
    const id = template.id;
    this.assetService.deleteAssetTemplate(id).subscribe(() => {
      this.loadEditDetails(this.selectedElem);
    });
  }
}
