<div *ngIf="loading">
  <p-progressBar mode="indeterminate"></p-progressBar>
</div>

<div *ngIf="!loading">
  <div *ngIf="edit">
    <div class="rsv2-sc-component panel" *ngIf="scalers.length">
      <div class="rsv2-sc-heading panel-heading">
        <span>
          {{ trans.trans.scalerTitle.value }}
          <i *ngIf="is_default == '1'" [matTooltip]="trans.trans.checkScalers.value" matTooltipPosition="right" class="fa fa-exclamation-triangle" style="color: red"></i>
          <i *ngIf="canEdit" (click)="editScaler()" class="fa fa-pencil pull-right clickable icon-primary"></i
        ></span>
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.scalerTitle"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <div class="rsv2-sc-body panel-body">
        <table style="width: 100%" class="table-spaced">
          <tr *ngFor="let scaler of scalers; let index = index">
            <td class="key" style="width: 60%">{{ scaler.name }}</td>
            <td class="param">{{ scaler.value_fmt }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>

  <div *ngIf="!edit">
    <div class="rsv2-sc-component panel">
      <div class="rsv2-sc-heading panel-heading">
        <span>{{ trans.trans.scalerTitle.value }}</span>
      </div>
      <div class="rsv2-sc-body panel-body">
        <div *ngIf="MasterScalerList.length">
          <div class="row">
            <div class="col-xs-12">
              <h3 style="margin-top: 0px">
                {{ trans.trans.primaryScaler.value }}
                <app-editTranslation
                  *ngIf="showTranslate"
                  [component]="trans.config.component"
                  [isTranslationV2]="true"
                  [transObj]="trans.trans.primaryScaler"
                  (callback)="getTranslations()"
                ></app-editTranslation>
              </h3>
            </div>
          </div>
          <div class="row">
            <div class="col-xs-12" *ngFor="let item of MasterScalerList; let i = index">
              <b>{{ item.name }}</b>
              <input type="text" name="masterscaler{{ item.id }}" class="form-control" [disabled]="!canEdit" [required]="item.greater_than_zero" [(ngModel)]="item.value" />
            </div>
          </div>
        </div>
        <div class="row" style="padding: 15px 0px" *ngFor="let scaler of scalers; let index = index">
          <div class="col-xs-12 scaler-list" style="padding-bottom: 10px" *ngIf="scaler.name != 'Does Not Scale'">
            <label style="font-weight: bold">{{ scaler.name }}</label
            ><br />
            <small *ngIf="scaler.description !== scaler.name">{{ scaler.description }}</small>
          </div>
          <div class="col-md-6 col-sm-6 col-xs-12 scaler-list">
            <input
              (keyup)="scalerUpdated(scaler)"
              type="text"
              class="form-control"
              [min]="0"
              id=""
              [(ngModel)]="scaler.value"
              [required]="scaler.greater_than_zero"
              name="scaler_{{ scaler.id }}"
              currencyMask
              [disabled]="!canEdit"
              [options]="{ prefix: '', thousands: valueProp.thousands, precision: scaler.factor_precision >= 0 ? scaler.factor_precision : 2, decimal: valueProp.decimal }"
              [attr.data-heap]="'value-execution-add-a-new-value-proposition-initial-customer-inputs-' + scaler.name.toLowerCase().split(' ').join('-')"
              [ngClass]="'value-execution-add-a-new-value-proposition-initial-customer-inputs-' + index"
            />
          </div>
        </div>

        <div class="row" *ngIf="saveLoading">
          <div class="col-xs-12">
            <p-progressBar mode="indeterminate"></p-progressBar>
          </div>
        </div>

        <div class="row" style="margin-top: 10px" *ngIf="!saveLoading">
          <div class="col-xs-12">
            <button type="button" class="btn btn_customizable pull-right value-execution-add-a-new-value-proposition-initial-customer-inputs-save-button" (click)="createScaler()" data-heap="value-execution-add-a-new-value-proposition-initial-customer-inputs-save-button">{{ trans.trans.save.value }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
