<!-- Show the saved situations if they have been saved already -->
<ng-container *ngIf="(situationsSaved && !showEditSituations && !isQuickCreate) || (!showEditSituations && !feature67 && !isQuickCreate)">
  <div class="">
    <div class="panel rsv2-sc-component">
      <div class="panel-heading rsv2-sc-heading">
        <span>
          {{ trans.trans.situationsTitle2.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.situationsTitle2"
            (callback)="getTranslations()"
          ></app-editTranslation>
          <i class="icon-primary" *ngIf="!locked && canEdit" (click)="showSituationEditing = !showSituationEditing" class="fa fa-pencil pull-right clickable icon-primary"></i>
        </span>
      </div>
      <div class="panel-body rsv2-sc-body">
        <p-progressBar *ngIf="loading" mode="indeterminate"></p-progressBar>
        <p *ngIf="!availableSituations.length && !loading">
          {{ trans.trans.no_special_situations.value }}
          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.no_special_situations"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </p>
        <div *ngFor="let elem of availableSituations">
          <ng-template #nodeTemplateRef let-node="node" let-index="index">
            <div class="node">
              <span [class.top_node__label]="index === 0">
                {{ node.name }}
              </span>
              <div *ngIf="node?.children?.length" class="node__children">
                <ng-template ngFor let-child [ngForOf]="node.children">
                  <ng-container *ngIf="child.selected">
                    <!-- Invoke the recursive template. -->
                    <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: child, index: index + 1 }"> </ng-template>
                  </ng-container>
                </ng-template>
              </div>
            </div>
          </ng-template>
          <!-- Initiate the recursive template rendering. -->
          <ng-template [ngTemplateOutlet]="nodeTemplateRef" [ngTemplateOutletContext]="{ node: elem, index: 0 }"> </ng-template>
        </div>

        <div *ngIf="questions && questions.size > 0">
          <div class="situation-edit-title">
            <h3>
              {{ trans.trans.qualificationQuestions.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [component]="trans.config.component"
                [isTranslationV2]="true"
                [transObj]="trans.trans.qualificationQuestions"
                (callback)="getTranslations()"
              ></app-editTranslation>
            </h3>
            <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans?.qualificationQuestionInfo2?.value"> info </span>
          </div>
          <div class="questionContainer" [class.editing]="q.value.open" *ngFor="let q of questions | keyvalue: asIsOrder">
            <div class="questionHeader">
              <h4 class="clickable" (click)="questionToggle(q.key, q.value)">
                <i class="fa fa-caret-right"></i>
                {{ q.value.name }}
              </h4>
            </div>
            <div class="questionAnswer">
              <ng-container *ngIf="q.value.open">
                <span>{{ trans.trans.descriptionLabel?.value }}</span>
                <p class="pre-wrap">{{ q.value.description }}</p>
              </ng-container>

              <span style="margin-top: 10px" *ngIf="q.value.open">{{ trans.trans.answerLabel?.value }}</span>
              <p class="pre-wrap" *ngIf="q.value.question_type_id !== '3'" [class.truncate]="!q.value.open">{{ q.value.answer }}</p>
              <p *ngIf="q.value.question_type_id === '3'">{{ q.value.answer === '1' ? 'Yes' : 'No' }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <p-sidebar [(visible)]="showSituationEditing" *ngIf="showSituationEditing" position="right" styleClass="keywest_sidebar p-sidebar-lg" (onHide)="closeSituations()">
    <div class="sidebar_wrapper">
      <div class="sidebar_header">
        {{ trans.trans.special_situations.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.special_situations"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </div>
      <ng-template [ngTemplateOutlet]="editSituation"> </ng-template>
    </div>
  </p-sidebar>
</ng-container>

<!-- Show the edit situation screen only without the panel for step 5 -->
<ng-container *ngIf="showEditSituations">
  <div class="models_panel">
    <div class="models_page_header_container_small mr-0">
      <div class="keywest_header mr-0 padding-0-y">
        <div class="row">
          <div class="col-xs-10">
            <div class="title padding-0-y">
              {{ trans.trans?.situations?.value }}
              <app-editTranslation
                *ngIf="showTranslate"
                [isTranslationV2]="true"
                [component]="trans.config.component"
                [transObj]="trans.trans.situations"
                (callback)="getTranslations()"
              ></app-editTranslation>
              <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans?.ch_situation?.value"> info </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <ng-template [ngTemplateOutlet]="editSituation"> </ng-template>
  </div>
</ng-container>

<!-- Show the edit situation the panel for step 1 if situtations have not been saved -->
<ng-container *ngIf="(!showEditSituations && !situationsSaved && feature67) || isQuickCreate">
  <div *ngIf="loading">
    <p-progressBar mode="indeterminate"></p-progressBar>
  </div>

  <div *ngIf="!loading">
    <!-- STEP 2 NOT done -->
    <div *ngIf="!situationsSaved || isQuickCreate">
      <div class="rsv2-sc-component panel">
        <div class="rsv2-sc-heading panel-heading">
          {{ trans.trans?.situationsTitle2?.value }}

          <app-editTranslation
            *ngIf="showTranslate"
            [component]="trans.config.component"
            [isTranslationV2]="true"
            [transObj]="trans.trans.situationsTitle2"
            (callback)="getTranslations()"
          ></app-editTranslation>
        </div>
        <div class="rsv2-sc-body panel-body">
          <ng-template [ngTemplateOutlet]="editSituation"> </ng-template>
          <div class="row" *ngIf="saveLoading">
            <div class="col-xs-12">
              <p-progressBar mode="indeterminate"></p-progressBar>
            </div>
          </div>
          <div class="row" *ngIf="!saveLoading">
            <div class="col-xs-12">
              <button class="btn btn_customizable" [ngClass]="{ 'pull-right': !isQuickCreate }" (click)="saveSituations()" [attr.data-heap]="!isQuickCreate ? 'value-execution-add-a-new-value-proposition-about-your-customer-opportunity-characteristics-continue-button' : 'value-execution-add-a-new-value-proposition-about-your-customer-opportunity-characteristics-next-button'" [ngClass]="!isQuickCreate ? 'value-execution-add-a-new-value-proposition-about-your-customer-opportunity-characteristics-continue-button' : 'value-execution-add-a-new-value-proposition-about-your-customer-opportunity-characteristics-next-button'">
                {{ !isQuickCreate ? trans.trans.continue.value : trans.trans.next.value }}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<!-- Edit situation common template code -->
<ng-template #editSituation>
  <div class="sidebar-grid padding-0-x padding-0-y mr-0" [class.sidebar_container]="situationsSaved">
    <div class="keywest_panel">
      <div *ngIf="loading">
        <p-progressBar mode="indeterminate"></p-progressBar>
      </div>
      <p *ngIf="!availableSituations.length && !loading">{{ trans.trans.contact.value }}</p>

      <ng-container
        *ngIf="
          (models && models[0] && models[0]?.account_solution_id === '26916') ||
            (!models && valueProp.solutions && valueProp.solutions[0] && valueProp.solutions[0]?.account_solution_id === '26916') ||
            feature88;
          else notFeature88
        "
      >
        <ng-template [ngTemplateOutlet]="feature88Template"> </ng-template>
      </ng-container>
    </div>
    <div class="blank-container"></div>
  </div>
  <p-progressBar *ngIf="saveLoading" mode="indeterminate"></p-progressBar>
  <div [ngClass]="{ row: crm, btn_container: !crm, relative: showSituationEditing || showEditSituations }" *ngIf="!locked && !isQuickCreate">
    <div class="col-xs-12">
      <app-are-you-sure
        *ngIf="showSituationEditing && !saveLoading"
        matTooltipPosition="above"
        [message]="trans.trans?.areYouSure?.value"
        class="btn pull-right cancel-confirm"
        icon=""
        [text]="trans.trans.back.value"
        (callbackYes)="closeSituations()"
      >
      </app-are-you-sure>
      <button
        class="btn btn_customizable"
        [ngClass]="showSituationEditing ? 'pull-right' : 'pull-left'"
        *ngIf="!saveLoading && (!hideSave || showSituationEditing)"
        (click)="saveSituations()"
      >
        {{ trans.trans.save.value }}
      </button>
    </div>
  </div>
</ng-template>

<!-- Edit situation and feature 88 enabled -->
<ng-template #feature88Template>
  <ng-container *ngFor="let situation of availableSituations">
    <div class="situation-edit-title">
      <h3>{{ situation.name }}</h3>
      <span *ngIf="situation.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situation.description"> info </span>
    </div>
    <div>
      <div [class.option-group]="child?.children?.length > 0" *ngFor="let child of situation.children">
        <div class="radio-select" [ngClass]="{ 'white-bg': situationsSaved, 'gray-bg': !situationsSaved }">
          <p-radioButton
            [name]="child.id"
            (ngModelChange)="populateDefaultOptions(child, situation.situation_type_id)"
            [value]="child"
            [disabled]="locked"
            [label]="child.name"
            [(ngModel)]="situationResponses.get(situation.situation_type_id).responses[0]"
          ></p-radioButton>
          <span *ngIf="child.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child.description"> info </span>
        </div>
        <div class="situation-options" [@openClose]="situationResponses.get(situation.situation_type_id).responses[0]?.id === child.id ? 'expanded' : 'collapsed'">
          <ng-container *ngFor="let item of situationResponses.get(situation.situation_type_id).responses">
            <div class="m-l-xxl">
              <div class="two-columns">
                <ng-container *ngIf="item?.children?.length">
                  <app-situation-input
                    [canEdit]="locked"
                    [situation]="item"
                    [feature88]="true"
                    [elementTypeId]="+situation.element_type_id"
                    [(model)]="situationResponses.get(situation.situation_type_id).children.get(item.id).responses"
                  ></app-situation-input>
                </ng-container>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-template [ngTemplateOutlet]="questionsPanel"> </ng-template>
</ng-template>

<!-- Edit situation and feature 88 not enabled -->
<ng-template #notFeature88>
  <ng-container *ngFor="let situation of availableSituations; let i = index">
    <!-- Title of situation -->
    <div class="situation-edit-title">
      <h3>{{ situation.name }}</h3>
      <span *ngIf="situation.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="situation.description"> info </span>
    </div>
    <div class="form-group m-l-xxl">
      <div class="situationContainer" id="multiselect-container">
        <ng-template #nodeTemplateRef2 let-item="item" let-reponsesRef="reponsesRef">
          <ng-container *ngIf="(+item.element_type_id | fallbackValue: +item.child_element_type_id) >= SituationInputType.Dropdown; else radioAndCheckbox">
            <ng-container *ngIf="(item.element_type_id ? +item.element_type_id : +item.child_element_type_id) === SituationInputType.Dropdown; else multiSelect">
              <p-dropdown
                [options]="item.children"
                [(ngModel)]="reponsesRef.responses[0]"
                [panelStyleClass]="'dropdown-item-font'"
                optionLabel="name"
                class="keywest_dropdown dropdown-theme"
                appendTo="body"
                [filter]="true"
                filterBy="name"
                [disabled]="locked"
                [autoDisplayFirst]="false"
              >
                <ng-template let-child pTemplate="selectedItem">
                  <span>
                    {{ child.name }}
                    <span *ngIf="child?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child?.description"> info </span>
                  </span>
                </ng-template>

                <ng-template let-child pTemplate="item">
                  <span>
                    {{ child.name }}
                    <span *ngIf="child?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child?.description"> info </span>
                  </span>
                </ng-template>
              </p-dropdown>
            </ng-container>
            <ng-template #multiSelect>
              <p-multiSelect
                [options]="item.children"
                [(ngModel)]="reponsesRef.responses"
                [panelStyleClass]="'dropdown-item-font'"
                [disabled]="locked"
                styleClass="panel-multi-select"
                optionLabel="name"
              >
                <ng-template let-child pTemplate="selectedItems">
                  <ng-container *ngFor="let option of reponsesRef.responses">
                    <span>
                      {{ option.name }}
                      <span *ngIf="option.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="option.description"> info </span>
                      <span *ngIf="reponsesRef?.responses.length > 1">,</span>
                    </span>
                  </ng-container>
                </ng-template>
                <ng-template let-child pTemplate="item">
                  <span>
                    {{ child.name }}
                    <span *ngIf="child?.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child?.description"> info </span>
                  </span>
                </ng-template>
              </p-multiSelect>
            </ng-template>
            <ng-template ngFor let-item [ngForOf]="reponsesRef.responses" [ngForTrackBy]="identify">
              <ng-container *ngIf="item?.children?.length">
                <div class="form-group m-l-xxl">
                  <div class="situationContainer">
                    <ng-template [ngTemplateOutlet]="nodeTemplateRef2" [ngTemplateOutletContext]="{ item: item, reponsesRef: reponsesRef.children.get(item.id) }"> </ng-template>
                  </div>
                </div>
              </ng-container>
            </ng-template>
          </ng-container>
          <ng-template #radioAndCheckbox>
            <ng-container *ngFor="let child of item.children; trackBy: identify">
              <div class="single-select">
                <ng-container *ngIf="(+item.element_type_id | fallbackValue: +item.child_element_type_id) === SituationInputType.Checkbox; else radioTemplate">
                  <p-checkbox [disabled]="locked" [name]="child.id" [value]="child" [label]="child.name" [(ngModel)]="reponsesRef.responses"></p-checkbox>
                </ng-container>
                <ng-template #radioTemplate>
                  <p-radioButton [name]="child.id" [value]="child" [label]="child.name" [disabled]="locked" [(ngModel)]="reponsesRef.responses[0]"></p-radioButton>
                </ng-template>

                <span *ngIf="child.description" class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="child.description"> info </span>
              </div>
              <ng-template ngFor let-item [ngForOf]="reponsesRef.responses" [ngForTrackBy]="identify">
                <ng-container *ngIf="item?.children?.length && item.id === child.id">
                  <div class="form-group m-l-xxl">
                    <div class="situationContainer">
                      <!-- Invoke the recursive template. -->
                      <ng-template [ngTemplateOutlet]="nodeTemplateRef2" [ngTemplateOutletContext]="{ item: item, reponsesRef: reponsesRef.children.get(item.id) }"> </ng-template>
                    </div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>
          </ng-template>
        </ng-template>
        <!-- Initiate the recursive template rendering. -->
        <ng-template [ngTemplateOutlet]="nodeTemplateRef2" [ngTemplateOutletContext]="{ item: situation, reponsesRef: situationResponses.get(situation.situation_type_id) }">
        </ng-template>
      </div>
    </div>
  </ng-container>
  <ng-template [ngTemplateOutlet]="questionsPanel"> </ng-template>
</ng-template>

<ng-template #questionsPanel>
  <ng-container *ngIf="questions && questions.size > 0">
    <div class="situation-edit-title">
      <h3>
        {{ trans.trans.qualificationQuestions.value }}
        <app-editTranslation
          *ngIf="showTranslate"
          [component]="trans.config.component"
          [isTranslationV2]="true"
          [transObj]="trans.trans.qualificationQuestions"
          (callback)="getTranslations()"
        ></app-editTranslation>
      </h3>
      <span class="material-icons-outlined tooltip-custom info-icon" [matTooltip]="trans.trans?.qualificationQuestionInfo2?.value"> info </span>
    </div>

    <div class="questionContainer" [class.editing]="q.value.openEditing" *ngFor="let q of questions | keyvalue: asIsOrder">
      <div class="questionHeader">
        <h4 class="clickable" (click)="questionToggle(q.key, q.value, 'openEditing')"><i class="fa fa-caret-right"></i> {{ q.value.name }}</h4>
        <i class="fa fa-pencil pull-right clickable" *ngIf="q.value.openEditing" (click)="editAnswer(q.key, q.value)"></i>
      </div>
      <div class="questionAnswer">
        <ng-container *ngIf="q.value.openEditing">
          <span>{{ trans.trans.descriptionLabel?.value }}</span>
          <p>{{ q.value.description }}</p>
        </ng-container>

        <span style="margin-top: 10px" *ngIf="q.value.openEditing">{{ trans.trans.answerLabel?.value }}</span>

        <ng-container *ngIf="!q.value.editing; else editingTemplate">
          <p *ngIf="q.value.question_type_id !== '3'">{{ q.value.editedAnswer }}</p>
          <p *ngIf="q.value.question_type_id === '3'">{{ q.value.editedAnswer === '1' ? 'Yes' : 'No' }}</p>
        </ng-container>
        <ng-template #editingTemplate>
          <ng-container [ngSwitch]="q.value.question_type_id" *ngIf="q.value.editing">
            <div *ngSwitchCase="1">
              <input type="text" [disabled]="locked" name="type1" [(ngModel)]="q.value.editedAnswer" class="form-control" [required]="q.value.required === '1'" />
            </div>
            <div *ngSwitchCase="2">
              <textarea
                cdkTextareaAutosize
                #autosize="cdkTextareaAutosize"
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="5"
                name="type2"
                [disabled]="locked"
                [(ngModel)]="q.value.editedAnswer"
                rows="3"
                class="form-control"
                [required]="q.value.required === '1'"
              ></textarea>
            </div>
            <div *ngSwitchCase="3">
              <p-inputSwitch
                name="type3"
                [disabled]="locked"
                [ngModel]="q.value.editedAnswer === '1' ? true : false"
                (onChange)="onToggleChange(q.key, q.value, $event)"
                [required]="q.value.required === '1'"
              ></p-inputSwitch>
            </div>
          </ng-container>
        </ng-template>
      </div>
    </div>
  </ng-container>
</ng-template>
